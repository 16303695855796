import React, { useState, useCallback } from 'react';
import Main from '../components/Main';
import ImgContact from '../images/contact.jpg';

export default function Contact() {
  const [message, setMessage] = useState('');
  const [data, setData] = useState({ email: '', phone: '', message: ''});

  const handleSubmit = useCallback(event => {
    // Prevent
    event.preventDefault();

    fetch('/api/messages', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json().then(({ message }) => setMessage(message)))
      .then(() => setData({ email: '', phone: '', message: ''}))
      .catch(() => setMessage('Une erreur est survenue. Veuillez ré-essayer.'));
  }, [data]);

  return (
    <Main>
      <div className="bg-white pt-12 pb-20 px-8 md:px-0">
        <div className="container mx-auto">
          <p className="mb-6 md:mb-12">
            <strong className="font-semibold text-3xl">Contactez-nous</strong>
          </p>
          <hr className="h-1 rounded-full bg-slate-200 w-64 mb-8 md:mb-16" />
          <div className="md:flex">
            <div className="mb-8">
              <p className="mb-4 text-xl text-slate-500">Horaires d'ouverture</p>
              <p className="font-semibold text-3xl mb-4">
                Du Lundi au Samedi
                <br />
                de 9h à 20h
              </p>
              <p className="mb-2">
                146 Boulevard de Charonne
                <br />
                75020 Paris
              </p>
              <p className="mb-2">
                <a href='mai&#108;to&#58;se%72ru%&#55;&#50;&#101;r&#105;ech&#97;ronne&#64;&#103;&#37;6Da&#105;l&#46;com'>s&#101;&#114;rure&#114;ie&#99;h&#97;ronne&#64;gma&#105;l&#46;com</a>
              </p>
              <p className="mb-2">
                <a href="tel://+33180506356">01 80 50 63 56</a>
              </p>
            </div>
            <img src={ImgContact} className="hidden md:block mx-20 mb-8" />
            <div className="hidden md:block mb-8">
              <p className="mb-2">
                <em>
                  Artisan serrurier depuis 20 ans
                  <br />
                  Pour les professionnels & particuliers.
                </em>
              </p>
              <p className="mb-4">
                Nous fournissons toutes les marques :
              </p>
              <ul className="list-disc ml-12 mb-4">
                <li>Bricard</li>
                <li>Vachette</li>
                <li>Abus</li>
                <li>Reelax</li>
                <li>Metalux</li>
                <li>Héracles</li>
              </ul>
              <p className="mb-2 italic text-slate-500">
                <em>Votre artisan serrurier</em>
              </p>
            </div>
          </div>
          <hr className="h-1 rounded-full bg-slate-200 w-16 md:w-64 mx-auto my-8 md:my-16" />
          {message && (
            <div
              className="p-4 mb-8 bg-blue-50 text-blue-900 border rounded border-blue-700 z-10 fixed top-24 left-1/2 -translate-x-1/2 w-11/12 md:w-auto"
              onClick={() => setMessage('')}
            >
              {message}
            </div>
           )}
          <form className="md:w-2/3 mx-auto" onSubmit={handleSubmit}>
            <div className="font-semibold mb-2">Email <sup className="text-red-600">*</sup></div>
            <input type="email" value={data.email} onChange={e => setData({ ...data, email: e.target.value })} className="p-2 border border-slate-500 w-full mb-8" required />
            <div className="font-semibold mb-2">N° de téléphone</div>
            <input value={data.phone} onChange={e => setData({ ...data, phone: e.target.value })} className="p-2 border border-slate-500 w-full mb-4" />
            <div className="font-semibold mb-2">Message <sup className="text-red-600">*</sup></div>
            <textarea value={data.message} onChange={e => setData({ ...data, message: e.target.value })} className="p-2 border border-slate-500 w-full mb-4" rows="4" required />
            <div className="text-center">
              <button type="submit" className="inline-block rounded mb-4 px-6 py-4 bg-green-700 text-white text-xl hover:bg-green-800">
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
    </Main>
  )
}
